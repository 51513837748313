@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);
html,
body {
  /*height: 100%;*/
  /*min-height:100%;*/
  min-width: 100%;
}

.welcome {
  visibility: hidden;
}

/*.full-height {*/
/*  height: 100%;*/
/*}*/
body {
  font-family: 'Source Sans Pro', monospace;
  margin-top:88px;
}
.col-about .description, .column, .second-title, .resource, .stay-connected-title {
  font-family: 'Source Sans Pro', monospace !important;
}
.col-about .first-title {
  font-family: mylogo;
}

.logo-font {
  font-family: mylogo !important;
  color:white;
}

.copyright {
  font-family: 'Source Sans Pro', monospace;
}

.breadcrumb {
  background-color: rgba(255, 0, 0, 0.0);
}

li.breadcrumb-item a {
  color: indianred;
}

.MuiBadge-colorError {
  background-color:indianred !important;
}

.bg-second {
  background-color:grey !important;
  margin-top: -20px;
}

.footer-container {
  width: 100vw !important;
  position: absolute;
  bottom: 0;
}

@font-face {
  font-family: mylogo;
  src: url(/static/media/hemi_head_bd_it.6591890a.ttf);
}

.glowtext-no {
  font-family: mylogo;
}

.dropdown-menu {
  text-align: center;
  /*display: inline-block;*/
  /*display: table;*/
  /*margin: 0 auto;*/
}
/*.checkout-basket {*/
/*  margin: 0 auto; !* Added *!*/
/*  float: none; !* Added *!*/
/*  margin-bottom: 10px; !* Added *!*/
/*}*/


/***************************************************************/
/* [1] The container */
.img-hover-zoom {
  height: 300px; /* [1.1] Set it as per your need */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform .5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.5);
}
/***************************************************************/
/* [1] The container */
.img-hover-zoom-prod {
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom-prod img {
  transition: transform .5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom-prod:hover img {
  transform: scale(1.5);
}
/***************************************************************/
.car-zoom {
  transition: transform 10s ease;
}
.car-zoom-active {
  transform: scale(1.2);
}
/***************************************************************/

.img-wrapper {
  display: inline-block;
  overflow: hidden;
}

.home-caro-zoom {
  display: inline-block;
  overflow: hidden;
}

.home-caro-img-wrap {
  /*width: 200px; any size*/
  height: 70vh; /*any size*/
  min-width: 100%;
}

.cookie-policy {
  position: fixed;
  bottom: 0;
  left:0;
  right: 0;
  background-color:rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.home-caro-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /*magic*/
}

.center-me {
  text-align: center;
}
.colz3{
  -webkit-column-count:3;
          column-count:3;
}
.colz1{
  -webkit-column-count:1;
          column-count:1;
}

.card-min-with {
  min-width: 300px;
  width: 100%;
}
.checkz:checked {
  color: indianred !important;
}

.MuiFormLabel-root.Mui-focused {
  color: indianred !important;
}
.MuiInput-underline:after {
  border-bottom: 2px solid indianred !important;
}

.second-col {
  color: indianred;
}

.second-bcol {
  background-color: grey;
}

.justify-bottom {
  position:absolute !important;
  bottom: 0;
}

.search_row {
  display: flex;
  flex-wrap: wrap; /* Optional. only if you want the items to wrap */
  justify-content: center; /* For horizontal alignment */
  align-items: center; /* For vertical alignment */
}

.center-content {
  display: table;
  margin: 0 auto;
}

.MuiTypography-colorTextSecondary {
  color:white !important;
}

.put-right {
  float:right;
}
.put-left {
  float:left;
}
.put-content-center {
  display: flex;
  justify-content: center;
}

input.custom-focus[type="search"]:focus {
  border-color: indianred;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

span {
  cursor:pointer;
}
.number {
  margin:100px;
}

.minus, .plus{
  width:20px;
  height:20px;
  background:#f2f2f2;
  border-radius:4px;
  padding:8px 5px 8px 5px;
  border:1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.number_inp {
  height: 34px;
  width: 100px;
  text-align: center;
  font-size: 26px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}

.transform-in {
  transform:scale(2);
  -ms-transform:scale(2); /* IE 9 */
  -moz-transform:scale(2); /* Firefox */
  -webkit-transform:scale(2); /* Safari and Chrome */
  -o-transform:scale(2); /* Opera */
}

.transform {
  transition: all 1s ease;
}

/*.prodimg:hover {*/
/*  cursor: zoom-in;*/
/*}*/

.MuiCheckbox-root {
  padding: 0px !important;
}
.MuiSelect-select.MuiSelect-select {
  width: 180px;
}

.form-control-search:focus {
  outline: 0 !important;
  border-color: currentColor;
  border-color: initial;
  box-shadow: none;
}

.MuiButton-root.Mui-disabled {
  color: black !important;
}

.row_underline{border-bottom:1px solid #ccc;}

.quantity_drop {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.required-field {
  color: indianred;
}

.stop_sliding {
  interval: false,
}

.thumbnail {
  width: 100px;
  max-width: 100px !important;
  height: 150px;
  max-height: 150px !important;
  padding: 0px !important;
  margin-bottom: 5px;
}

.thumbnail_cart {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 50px;
}

.link_element {
  cursor: pointer;
}

.nospace_element {
  margin: 0px;
  padding:0px;
}

.inline_elements {
  float: left;
}

#home_cards {
  margin-top:15px;
  margin-bottom:15px;
}


.outer_container {
  transform-origin: top left;
  max-height: 0;
}

.container {
  position: relative;
  transform-origin: top left;
}

/************************* BLINK ME -> START ************************************/
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
/************************* BLINK ME -> END ************************************/

/************************* BUBBLE -> START ************************************/
.speech-bubble {
  position: relative;
  background: #00aabb;
  border-radius: .4em;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-left-color: #00aabb;
  border-right: 0;
  border-bottom: 0;
  margin-top: -10px;
  margin-right: -20px;
}

/************************* BUBBLE -> END ************************************/

.no-pads {
  padding: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.greyout {
color: lightgrey !important;
}
/************** THINGS THAT NEED TO SCALE -> START ********************/
.shop-product-card-body {
  min-height: 0px;
}
.wide-cookie {
  display: none;
}
.mobile-cookie {
  display: inherit;
}
.confirm-table {
  font-size: small;
}
.no-results {
  font-size: medium;
  text-align: center;
  width: 100vw;
}
.cart-button {
  padding: 3px !important;
}
.MuiTypography-h6 {
  font-size: small !important;
}
.MuiTypography-body1 {
  font-size: xx-small !important;
}
.MuiTimelineContent-root {
  padding: 0px !important;
  max-width: 200px !important;
}
.MuiTimelineOppositeContent-root {
  padding: 0px !important;
  max-width: 100px !important;
  margin: 0px !important;
}
.order-paper {
  padding: 5px 6px !important;
  max-width: 180px;
  margin: 2px;
}

#home-quote {
  width:190px;
  margin-left: calc(33vw - 95px) !important;
  margin-top: 5px;
  font-size: 1.0em;
  animation: color-change 5s infinite;
}
@keyframes color-change {
  50% { color: white; }
  0% { color: #212529; }

}
.notifier {
  display: flex;
  margin-left: calc(50vw - 50px);
  margin-top: 20px;
  width: 100px;
  z-index: 5;
  position: absolute;
  font-size: small;
  text-align: center;
}

.insta-container {
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  max-width: 339px;
  max-height: 450px;
}

.contact-form {
  margin: auto;
  width:100%;
}

.body_all {
  width:100%;
  min-height: 65vh;
}
.dropdown-item.active {
  background-color: indianred !important;
}

#muscle-drop:focus:not(:focus-visible){
  outline: none !important;
}
#muscle-drop:focus{
  outline: none !important;
  box-shadow: none;
}

#muscle-drop {
  font-size: small;
  border: none !important;
  color: black;
}

.show > #muscle-drop {
  background-color: white;
  color:grey;
}
#muscle-drop:hover {
  color: indianred;
  background-color: white;
}

.footer-button {
  width: 40px;
  font-size: small;
}

.confirm-table {
  padding-left: 0px;
  padding-right: 0px;
}

.wide-footer-buttons {
  display:none
}
.small-footer-buttons {
  display: inherit;
  margin-top: 10px;
}
.wide-footer-links {
  display:none
}
.small-footer-links {
  display: inherit;
}
.prod-search {
  /*max-width: 72px !important;*/
  display:none;
}
.pop-search-button {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
#popup-search {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.nopop-span{
  display:none;
}
.pop-span {
  display: inherit;
}

.search-wrap-s {
 padding: 0px !important;
  width: 40px !important;
}
.brand-logo{
  padding: 0px !important;
  margin: 0px !important;
}
.basket-wrap-s {
  margin: 0px !important;
}
.search-button {
  border-radius: 0.25rem !important;
}
.break-quoute-small {
  display:inherit;
}
.break-quoute {
  display:none;
}
.row_underline {
  font-size: small;
}
.cart-image {
  max-width: 60px;
  padding-right: 3px !important;
  padding-left: 0px !important;
}
.cart-body {
  padding-left: 0px;
  padding-right: 0px;
  width:100vw;
}
.cart-total {
  font-size: medium;
}
.promo-input {
  width: 70vw;
}
.checkout-button-cart {
  width: 90vw;
}
/*********MEDIUM START**********************/
@media screen and  (min-width: 400px) {
  /*.prod-search {*/
  /*  max-width: 72px !important;*/
  /*  display:inherit;*/
  /*}*/
  /*.search-button {*/
  /*  border-radius: 0.25rem !important;*/
  /*  border-bottom-left-radius: 0px !important;*/
  /*  border-top-left-radius: 0px !important;*/
  /*}*/
  /*.search-wrap-s {*/
  /*  padding: 0px !important;*/
  /*  width: inherit !important;*/
  /*}*/
  /*.break-quoute {*/
  /*  display:inherit;*/
  /*}*/
  /*.break-quoute-small {*/
  /*  display:none;*/
  /*}*/
  /*.nopop-span{*/
  /*  display:inherit;*/
  /*}*/
  /*.pop-span {*/
  /*  display: none;*/
  /*}*/
}
/*********MEDIUM END**********************/

@media screen and (min-width: 690px) {
  .shop-product-card-body {
    min-height: 180px;
  }
  .wide-cookie {
    display: inherit;
  }
  .mobile-cookie {
    display: none;
  }
  .confirm-table {
    font-size: inherit;
  }
  .cart-button {
    padding: 12px !important;
  }
  .no-results {
    font-size: large;
  }
  .prod-search {
    display:inherit;
    max-width: 400px !important;
  }
  .nopop-span{
    display:inherit;
  }
  .pop-span {
    display: none;
  }
  .search-button {
    border-radius: 0.25rem !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
  .search-wrap-s {
    padding: 0px !important;
    width: inherit !important;
  }
  /* On large screens, increase size of muscle_label */
  .break-quoute-small {
    display:none;
  }
  .break-quoute {
    display: inherit;
  }
  .wide-footer-buttons {
    display: inherit;
  }
  .small-footer-buttons {
    display:none
  }
  .wide-footer-links {
    display: inherit;
  }
  .small-footer-links {
    display:none
  }

  .confirm-table {
    padding-left: 100px;
    padding-right: 100px;
  }

  .footer-button {
    margin-top: 30px;
    width: 180px;
    font-size: inherit;
  }

  .body_all {
    min-height: 85vh;
  }

  .MuiTypography-h6 {
    font-size: medium !important;
  }
  .MuiTypography-body1 {
    font-size: small !important;
  }
  .MuiTimelineContent-root {
    font-size: medium !important;
    padding: 6px 16px !important;
    max-width: 100% !important;
  }
  .MuiTimelineOppositeContent-root {
    font-size: medium !important;
    padding: 6px 16px !important;
    margin: 0px !important;
  }
  .order-paper {
    padding: 6px 16px !important;
    max-width: 100% !important;
  }
  #muscle-drop {
    font-size: inherit;
  }

  #home-quote {
    font-size: 1.6em;
    width:480px;
    margin-top: 20px;
    margin-left: calc(30vw - 240px) !important;
  }

  .contact-form {
    width:50%;
  }

  /* On large screens, increase size of insta-container */
  .insta-container {
    max-width: 690px;
    max-height: 790px;
  }

  /* On large screens, increase size of notfier */
  .notifier {
    margin-top: 50px;
    font-size: inherit;
  }
  .row_underline {
    font-size: 16px;
  }
  .cart-image {
    max-width: inherit;
    padding: 0px 15px !important;
  }
  .cart-body {
    padding: 20px;
  }
  .cart-total {
    font-size: 1.25rem;
  }
  .promo-input {
    width: 300px;
  }
  .checkout-button-cart {
    width: 300px;
  }
}
/************** XXXXXXL SCREENS ********************/
@media screen and (min-width: 1400px) {
  .break-quoute {
    display:none;
  }
  #home-quote {
    font-size: 1.6em;
    width:900px;
    margin-top: 20px;
    margin-left: calc(30vw - 450px) !important;
  }
}
/************** THINGS THAT NEED TO SCALE -> END ********************/
li {
  margin:0 0 4px 0;
}

.navbar-collapse.collapse.show {
}

.message {
  color: pink;
}

.exercises {
  position: relative;
  width:100%;
}

.muscle_info {
  padding-left:0;
  margin-left:0;
  z-index: 10;
}

.thumbnail_column {
  flex: 0 0 230px;
  background-color: greenyellow;
}

@media (max-width: 690px) {
  .thumbnail_column {
    display: none;
  }
}

.flex-fixed-width-item {
  flex: 0 0 100px;
}

.no-padding {
  padding: 0 !important;
}

accordion {
  width: 100%;
}

.orderinfo-columns-1 {
  -webkit-column-count: 1;
          column-count: 1;
}
.orderinfo-columns-2 {
  -webkit-column-count: 2;
          column-count: 2;
}
.space-on-top {
  margin-top: 10px;
}
.navbar li .glyphicon {
    margin-right: 10px;
}

/* Highlighting rules for nav menu items */
.navbar .navbar-nav .active a,
.navbar .navbar-nav .active a:hover,
.navbar .navbar-nav .active a:focus {
    background-image: none;
}

.navbar {
    padding: 1rem 1rem;
}

.fade-from-bottom {
    /*-webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));*/
    background: linear-gradient(to bottom, transparent, black);
}

.navbar-light .navbar-brand {
    color: white;
}

.bg-dark {
    background-color: black !important;
    /*background: linear-gradient(to bottom, black, transparent) !important;*/
}

.navbar-dark .navbar-nav .nav-link {
    /*color: white;*/
}

.outer_container {
    width:100%;
}

.container {
    width:100%;
    min-width: 100%; 
    background-size: cover;
}

.navbasket-right{
    display: none;
}
.navbasket-collapse{
    display: inherit;
}

@media (min-width: 768px) {
    /* On large screens, convert the nav menu to a vertical sidebar */
    .navbasket-right{
        display: inherit;
    }

    .navbasket-collapse{
        display: none;
    }
    /*.navbar {
        height: 100%;
        width: calc(25% - 20px);
    }

    .navbar {
        border-radius: 0;
        border-width: 0;
        height: 100%;
    }



    .navbar-header {
        float: none;
    }

    .navbar .navbar-collapse {
        border-top: 1px solid #444;
        padding: 0;
    }

    .navbar .container-fluid {
        padding: 0;
        margin: 0;
    }

        .navbar .container-fluid .navbar-brand {
            margin: 0;
        }

    .navbar ul {
        float: none;
    }

    .navbar li {
        float: none;
        font-size: 15px;
        margin: 6px;
    }

        .navbar li a {
            padding: 10px 16px;
            border-radius: 4px;
        }

    .navbar a {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }*/
}


.newin {
    width: 300px;
}

/***************************************************************/
/***************************************************************/

/***************************************************************/
.line-1{
    position: relative;
    top: 50%;
    width: 24em;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
}

/* Animation */
.anim-typewriter{
    animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter{
    from{width: 0;}
    to{width: 24em;}
}
@keyframes blinkTextCursor{
    from{border-right-color: rgba(255,255,255,.75);}
    to{border-right-color: transparent;}
}
/***************************************************************/
/* Centered text */
.centered {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}
/***************************************************************/

/*****************/
.text-col{
    color: indianred;
}
/*****************/
.ml12 {
    position: absolute;
    top: 40%;
    left: 20%;
    transform: translate(-50%, -50%);

    font-weight: 200;
    font-size: 1.8em;
    text-transform: uppercase;
    letter-spacing: 0.5em;
}

.ml12 .letter {
    display: inline-block;
    line-height: 1em;
}
/*****************/
/* Bottom right text */
.bottom-middle {
    position: absolute;
    bottom: 8px;
    left: 50%;
}
/***************************************************************/
#ProgressBar {
    border-radius: 100px;
    animation: glow 1s infinite alternate;

    margin: 0 auto;
    width: 282px;
    height: 18px;
    border: 1px solid black;
    border-radius: 0px;
    padding: 2px;
    background-color: black;
}

#Progress {
    width: 0%;
    border-left: 0px;
    border-right: 9px solid transparent;
    border-top: 12px solid white;
    height: 100%;

    animation-name: progressBar;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}
@keyframes progressBar {
    0% {
        width: 10%;
    }

    100% {
        width: 78%;
    }
}

@keyframes glow {
    from {
        box-shadow: 0 0 3px -2px white;
    }
    to {
        box-shadow: 0 0 3px 2px white;
    }
}
/*****************/
.glowtext {
    color:black;
    animation: glowtext 1s ease-in-out infinite alternate;
}

@keyframes glowtext {
    from {
        text-shadow: 0 0 10px indianred;
    }
    to {
        text-shadow: 0 0 10px indianred;
    }
}
/***************************************************************/

.home-text {
    text-align: center;
}
.space-top {
    padding-top: 45vh;
}

div.Caption {
    display: none;
    visibility: hidden;
}

#gx {
    background-size: contain;
    padding: 10% 0;
    text-align: center;
    width: 100%;
    height: auto;
}
/***************************************************************/
.glowcol {
    background: 50% 100% / 50% 50% no-repeat radial-gradient(ellipse at bottom, #fff, transparent, transparent);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 10vw;
    font-family: "Source Sans Pro", sans-serif;
    animation: reveal 3000ms ease-in-out forwards 200ms,
    glowz 2500ms linear infinite 2000ms;
}

@keyframes reveal {
    80%{
        letter-spacing: 8px;
    }
    100% {
        background-size: 300% 300%;
    }
}
@keyframes glowz {
    40% {
        text-shadow: 0 0 8px #fff;
    }
}



#insta-container {
    height: 80px;
}

div.Caption {
    display: none;
    visibility: hidden;
}

#gx {
    background-size: contain;
    padding: 10% 0;
    text-align: center;
    width: 100%;
    height: auto;
}

.gx-image {
    animation: tronFilter 12s infinite;
    fill: #000000;
    stroke: #666;
    stroke-width: 5;
    stroke-linecap: round;
    stroke-linejoin: bevel;
}

.gx-image2 {
    animation: myShine 2s infinite;
    fill: #000000;
    stroke: #666;
    stroke-width: 5;
    stroke-linecap: round;
    stroke-linejoin: bevel;
}

@keyframes tronFilter {
    0% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #31eefd );
        filter: drop-shadow( -.75px 0px 6px #31eefd );
        stroke: #31eefd;
    }

    22% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #1d85bb );
        filter: drop-shadow( -.75px 0px 6px #1d85bb );
        stroke: #1d85bb;
    }
    /* end blue */
    42% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #884173 );
        filter: drop-shadow( -.75px 0px 6px #884173 );
        stroke: #884173;
    }

    62% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #ff0071 );
        filter: drop-shadow( -.75px 0px 6px #ff0071 );
        stroke: #ff0071;
    }
    /* end magenta */
    82% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #009997 );
        filter: drop-shadow( -.75px 0px 6px #009997 );
        stroke: #009997;
    }

    92% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #92ffd7 );
        filter: drop-shadow( -.75px 0px 6px #92ffd7 );
        stroke: #92ffd7;
    }
    /* end green */
    100% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #31eefd );
        filter: drop-shadow( -.75px 0px 6px #31eefd );
        stroke: #31eefd;
    }
}

@keyframes myShine {
    0% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #FFFFFF );
        filter: drop-shadow( -.75px 0px 6px #FFFFFF );
        stroke: #FFFFFF;
    }
    50% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #000000 );
        filter: drop-shadow( -.75px 0px 6px #000000 );
        stroke: #000000;
    }
    100% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #FFFFFF );
        filter: drop-shadow( -.75px 0px 6px #FFFFFF );
        stroke: #FFFFFF;
    }
}

/***** youtube title - start ************/
.ani-p {
    text-transform: uppercase;
    letter-spacing: .5em;
    display: inline-block;
    margin: 0px;
}

.ani-span {
    font: 700 1em/1 "Oswald", sans-serif;
    letter-spacing: 0;
    display: block;
    margin: 0 auto;
    text-shadow: 0 0 80px rgba(255, 255, 225, .5);

    /* Clip Background Image */

    background: url(/static/media/animated-text-fill.3c7fc003.png) repeat-y;
    -webkit-background-clip: text;
    /*background-clip: text;*/

    /* Animate Background Image */

    -webkit-text-fill-color: transparent;
    -webkit-animation: ani 80s linear infinite;

    /* Activate hardware acceleration for smoother animations */

    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
}

/* Animate Background Image */

@keyframes ani {
    0% { background-position: 0% 50%; }
    100% { background-position: 100% 50%; }
}

.ani-div { font: 400 1em/1.5 "Neuton"; background: black;
    color: rgba(255,255,255,0);
    text-align: center;
    margin: 0;
    width: 100%;
    height: 100%;
}
.ani-icon {
    color:white;
    margin-top: -6px;
}

.ani-accord {
    padding: 0px !important;
    background-color: black !important;
    height: 50px;
}
.MuiAccordion-rounded:last-child,
.MuiAccordion-rounded:first-child {
    border: 0px !important;
}

.ani-card {
    border: 0px !important;
    border-radius: 0px !important;
    margin:0px !important;
    color: white;
    background-color: black;
}
.ani-columns {
    -webkit-column-count: 2;
            column-count: 2;
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
    background-color: black;
}
.on-body-top {
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;
}
/*NEEDS TO SCALE - SMALL****************************/
.muscle_label {
    position: absolute;
    color: #fff;
    padding: 5px;
    background: rgba(0,0,0,0.6);
    transform: translate3d(-50%, -50%, 0);
    border-radius: 3px;
    pointer-events: none;
    z-index: 4;
    margin-left: calc(342px - 50vw);
    font-size: small;
}
.flip-body {
    margin-bottom: 10px !important;
    display:inherit;
    background-color: transparent !important;
    color: black !important;
}
.flip-body.rot {
    animation: rotate 1s infinite;
    background-color: transparent !important;
}
.flip-body:active {
    background-color: transparent !important;
}
@keyframes rotate {
    0%  { transform: rotate(0deg);  }
    100%{ transform: rotate(180deg); }
}

.on-body {
    position: absolute;
    top: 747px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;
}
.youtube-timeline {
    flex: 1 0 30%;
    max-width: 20%;
    margin: 0px !important;
    padding: 0px !important;
    font-size: x-small;
}
.inner_body {
    display: flex;
    justify-content: center;
    position: relative;
    left: -10px;
    height: 720px;
    width: 680px;
}
.body-slide {
    direction:ltr;
    width:341px;
    background-size: cover;
    overflow: hidden;
}
.body-slide.next {
    direction:rtl;
    margin-right:50px
}

.b_loading {
    overflow: visible;
}
.b_loaded {
    overflow: hidden;
}

.space-top {
    margin-top: -320px;
}

.youtube-card {
    flex: 1 0 70%;
    max-width: 80%;
    padding: 0px !important;
}
.MuiTimeline-root{
    padding: 6px 0px !important;
}
.MuiTimelineContent-root {
    padding: 6px 3px !important;
    font-size: xx-small;
}

#overlay_image {
    position: absolute;
    left:0px;
    top: 0px;
    z-index: 3;
    pointer-events: none;
}
.body-background {
    /*background-image: url("/images/367502-PAXLKG-681.jpg");*/
    width:339px;
    background-size: cover;
    overflow: hidden;
    margin-left: calc(50vw - 172px);
}
/*NEEDS TO SCALE - LARGE****************************/
@media screen and (min-width: 690px) {
    .muscle_label {
        padding: 10px;
        border-radius: 5px;
        font-size: inherit;
        margin-left: 0px;
    }
    .flip-body {
        display:none !important;
    }
    .youtube-timeline {
        font-size: medium;
    }
    .inner_body {
        height: 650px;
        left:inherit;
    }
    .space-top {
        margin-top: -260px;
    }
    .youtube-card {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .MuiTimeline-root{
        padding: 6px 16px !important;
    }
    .MuiTimelineContent-root {
        padding: 6px 16px !important;
        font-size: medium;
    }
    #overlay_image {
        left: inherit;
    }
    .body-background {
        overflow: visible;
        margin-left: 0px;
        width: 100%;
        min-width: 100%;
    }
    .body-background.next {
        margin-left: 0px;
    }
    .on-body {
        top: 70%;
    }
    .body-slide {
        width:100%;
        overflow: visible;
    }
}
/*NEEDS TO SCALE - END***************************/
.youtube-list {
    padding-top: 18px;
    width: 100%;
}

.clickable {cursor: pointer;}
.unclickable {cursor: default;}
.youtube-vid {
    width: 100%;
}

.MuiTimelineItem-missingOppositeContent:before {
    padding: 0px !important;
    width: 0px !important;
    margin: 0px !important;
    flex:0 1 !important;
}
.MuiTimelineDot-outlinedPrimary {
    border-color: indianred!important;
}

.timeline-title-glow {
    animation: tronFilter 3s infinite;
}
/***** youtube title - end ************/
/***** dot dot dot loader - start ************/
.spinner-dot {
    margin: 0px auto 0;
    width: 100px;
    text-align: center;
}

.spinner-dot > div {
    width: 10px;
    height: 10px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-dot .bounce1 {
    animation-delay: -0.32s;
}

.spinner-dot .bounce2 {
    animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        transform: scale(0);
    } 40% {
          transform: scale(1.0);
      }
}
/***** dot dot dot loader - end ************/
/***** fade animation - start ************/
/***** fade animation - end ************/
/* loading dots start *********************/
.loading-dots:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
    0%, 20% {
        color: rgba(0,0,0,0);
        text-shadow:
                .25em 0 0 rgba(0,0,0,0),
                .5em 0 0 rgba(0,0,0,0);}
    40% {
        color: white;
        text-shadow:
                .25em 0 0 rgba(0,0,0,0),
                .5em 0 0 rgba(0,0,0,0);}
    60% {
        text-shadow:
                .25em 0 0 white,
                .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
        text-shadow:
                .25em 0 0 white,
                .5em 0 0 white;}}
/* loading dots end *********************/
