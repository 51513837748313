@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");
@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);
html,
body {
  /*height: 100%;*/
  /*min-height:100%;*/
  min-width: 100%;
}

.welcome {
  visibility: hidden;
}

/*.full-height {*/
/*  height: 100%;*/
/*}*/
body {
  font-family: 'Source Sans Pro', monospace;
  margin-top:88px;
}
.col-about .description, .column, .second-title, .resource, .stay-connected-title {
  font-family: 'Source Sans Pro', monospace !important;
}
.col-about .first-title {
  font-family: mylogo;
}

.logo-font {
  font-family: mylogo !important;
  color:white;
}

.copyright {
  font-family: 'Source Sans Pro', monospace;
}

.breadcrumb {
  background-color: rgba(255, 0, 0, 0.0);
}

li.breadcrumb-item a {
  color: indianred;
}

.MuiBadge-colorError {
  background-color:indianred !important;
}

.bg-second {
  background-color:grey !important;
  margin-top: -20px;
}

.footer-container {
  width: 100vw !important;
  position: absolute;
  bottom: 0;
}

@font-face {
  font-family: mylogo;
  src: url(hemi_head_bd_it.ttf);
}

.glowtext-no {
  font-family: mylogo;
}

.dropdown-menu {
  text-align: center;
  /*display: inline-block;*/
  /*display: table;*/
  /*margin: 0 auto;*/
}
/*.checkout-basket {*/
/*  margin: 0 auto; !* Added *!*/
/*  float: none; !* Added *!*/
/*  margin-bottom: 10px; !* Added *!*/
/*}*/


/***************************************************************/
/* [1] The container */
.img-hover-zoom {
  height: 300px; /* [1.1] Set it as per your need */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform .5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.5);
}
/***************************************************************/
/* [1] The container */
.img-hover-zoom-prod {
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom-prod img {
  transition: transform .5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom-prod:hover img {
  transform: scale(1.5);
}
/***************************************************************/
.car-zoom {
  transition: transform 10s ease;
}
.car-zoom-active {
  transform: scale(1.2);
}
/***************************************************************/

.img-wrapper {
  display: inline-block;
  overflow: hidden;
}

.home-caro-zoom {
  display: inline-block;
  overflow: hidden;
}

.home-caro-img-wrap {
  /*width: 200px; any size*/
  height: 70vh; /*any size*/
  min-width: 100%;
}

.cookie-policy {
  position: fixed;
  bottom: 0;
  left:0;
  right: 0;
  background-color:rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.home-caro-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /*magic*/
}

.center-me {
  text-align: center;
}
.colz3{
  column-count:3;
}
.colz1{
  column-count:1;
}

.card-min-with {
  min-width: 300px;
  width: 100%;
}
.checkz:checked {
  color: indianred !important;
}

.MuiFormLabel-root.Mui-focused {
  color: indianred !important;
}
.MuiInput-underline:after {
  border-bottom: 2px solid indianred !important;
}

.second-col {
  color: indianred;
}

.second-bcol {
  background-color: grey;
}

.justify-bottom {
  position:absolute !important;
  bottom: 0;
}

.search_row {
  display: flex;
  flex-wrap: wrap; /* Optional. only if you want the items to wrap */
  justify-content: center; /* For horizontal alignment */
  align-items: center; /* For vertical alignment */
}

.center-content {
  display: table;
  margin: 0 auto;
}

.MuiTypography-colorTextSecondary {
  color:white !important;
}

.put-right {
  float:right;
}
.put-left {
  float:left;
}
.put-content-center {
  display: flex;
  justify-content: center;
}

input.custom-focus[type="search"]:focus {
  border-color: indianred;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

span {
  cursor:pointer;
}
.number {
  margin:100px;
}

.minus, .plus{
  width:20px;
  height:20px;
  background:#f2f2f2;
  border-radius:4px;
  padding:8px 5px 8px 5px;
  border:1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.number_inp {
  height: 34px;
  width: 100px;
  text-align: center;
  font-size: 26px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}

.transform-in {
  transform:scale(2);
  -ms-transform:scale(2); /* IE 9 */
  -moz-transform:scale(2); /* Firefox */
  -webkit-transform:scale(2); /* Safari and Chrome */
  -o-transform:scale(2); /* Opera */
}

.transform {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

/*.prodimg:hover {*/
/*  cursor: zoom-in;*/
/*}*/

.MuiCheckbox-root {
  padding: 0px !important;
}
.MuiSelect-select.MuiSelect-select {
  width: 180px;
}

.form-control-search:focus {
  outline: 0 !important;
  border-color: initial;
  box-shadow: none;
}

.MuiButton-root.Mui-disabled {
  color: black !important;
}

.row_underline{border-bottom:1px solid #ccc;}

.quantity_drop {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.required-field {
  color: indianred;
}

.stop_sliding {
  interval: false,
}

.thumbnail {
  width: 100px;
  max-width: 100px !important;
  height: 150px;
  max-height: 150px !important;
  padding: 0px !important;
  margin-bottom: 5px;
}

.thumbnail_cart {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 50px;
}

.link_element {
  cursor: pointer;
}

.nospace_element {
  margin: 0px;
  padding:0px;
}

.inline_elements {
  float: left;
}

#home_cards {
  margin-top:15px;
  margin-bottom:15px;
}


.outer_container {
  transform-origin: top left;
  max-height: 0;
}

.container {
  position: relative;
  transform-origin: top left;
}

/************************* BLINK ME -> START ************************************/
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
/************************* BLINK ME -> END ************************************/

/************************* BUBBLE -> START ************************************/
.speech-bubble {
  position: relative;
  background: #00aabb;
  border-radius: .4em;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-left-color: #00aabb;
  border-right: 0;
  border-bottom: 0;
  margin-top: -10px;
  margin-right: -20px;
}

/************************* BUBBLE -> END ************************************/

.no-pads {
  padding: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.greyout {
color: lightgrey !important;
}
/************** THINGS THAT NEED TO SCALE -> START ********************/
.shop-product-card-body {
  min-height: 0px;
}
.wide-cookie {
  display: none;
}
.mobile-cookie {
  display: inherit;
}
.confirm-table {
  font-size: small;
}
.no-results {
  font-size: medium;
  text-align: center;
  width: 100vw;
}
.cart-button {
  padding: 3px !important;
}
.MuiTypography-h6 {
  font-size: small !important;
}
.MuiTypography-body1 {
  font-size: xx-small !important;
}
.MuiTimelineContent-root {
  padding: 0px !important;
  max-width: 200px !important;
}
.MuiTimelineOppositeContent-root {
  padding: 0px !important;
  max-width: 100px !important;
  margin: 0px !important;
}
.order-paper {
  padding: 5px 6px !important;
  max-width: 180px;
  margin: 2px;
}

#home-quote {
  width:190px;
  margin-left: calc(33vw - 95px) !important;
  margin-top: 5px;
  font-size: 1.0em;
  animation: color-change 5s infinite;
}
@keyframes color-change {
  50% { color: white; }
  0% { color: #212529; }

}
.notifier {
  display: flex;
  margin-left: calc(50vw - 50px);
  margin-top: 20px;
  width: 100px;
  z-index: 5;
  position: absolute;
  font-size: small;
  text-align: center;
}

.insta-container {
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  max-width: 339px;
  max-height: 450px;
}

.contact-form {
  margin: auto;
  width:100%;
}

.body_all {
  width:100%;
  min-height: 65vh;
}
.dropdown-item.active {
  background-color: indianred !important;
}

#muscle-drop:focus:not(:focus-visible){
  outline: none !important;
}
#muscle-drop:focus{
  outline: none !important;
  box-shadow: none;
}

#muscle-drop {
  font-size: small;
  border: none !important;
  color: black;
}

.show > #muscle-drop {
  background-color: white;
  color:grey;
}
#muscle-drop:hover {
  color: indianred;
  background-color: white;
}

.footer-button {
  width: 40px;
  font-size: small;
}

.confirm-table {
  padding-left: 0px;
  padding-right: 0px;
}

.wide-footer-buttons {
  display:none
}
.small-footer-buttons {
  display: inherit;
  margin-top: 10px;
}
.wide-footer-links {
  display:none
}
.small-footer-links {
  display: inherit;
}
.prod-search {
  /*max-width: 72px !important;*/
  display:none;
}
.pop-search-button {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
#popup-search {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.nopop-span{
  display:none;
}
.pop-span {
  display: inherit;
}

.search-wrap-s {
 padding: 0px !important;
  width: 40px !important;
}
.brand-logo{
  padding: 0px !important;
  margin: 0px !important;
}
.basket-wrap-s {
  margin: 0px !important;
}
.search-button {
  border-radius: 0.25rem !important;
}
.break-quoute-small {
  display:inherit;
}
.break-quoute {
  display:none;
}
.row_underline {
  font-size: small;
}
.cart-image {
  max-width: 60px;
  padding-right: 3px !important;
  padding-left: 0px !important;
}
.cart-body {
  padding-left: 0px;
  padding-right: 0px;
  width:100vw;
}
.cart-total {
  font-size: medium;
}
.promo-input {
  width: 70vw;
}
.checkout-button-cart {
  width: 90vw;
}
/*********MEDIUM START**********************/
@media screen and  (min-width: 400px) {
  /*.prod-search {*/
  /*  max-width: 72px !important;*/
  /*  display:inherit;*/
  /*}*/
  /*.search-button {*/
  /*  border-radius: 0.25rem !important;*/
  /*  border-bottom-left-radius: 0px !important;*/
  /*  border-top-left-radius: 0px !important;*/
  /*}*/
  /*.search-wrap-s {*/
  /*  padding: 0px !important;*/
  /*  width: inherit !important;*/
  /*}*/
  /*.break-quoute {*/
  /*  display:inherit;*/
  /*}*/
  /*.break-quoute-small {*/
  /*  display:none;*/
  /*}*/
  /*.nopop-span{*/
  /*  display:inherit;*/
  /*}*/
  /*.pop-span {*/
  /*  display: none;*/
  /*}*/
}
/*********MEDIUM END**********************/

@media screen and (min-width: 690px) {
  .shop-product-card-body {
    min-height: 180px;
  }
  .wide-cookie {
    display: inherit;
  }
  .mobile-cookie {
    display: none;
  }
  .confirm-table {
    font-size: inherit;
  }
  .cart-button {
    padding: 12px !important;
  }
  .no-results {
    font-size: large;
  }
  .prod-search {
    display:inherit;
    max-width: 400px !important;
  }
  .nopop-span{
    display:inherit;
  }
  .pop-span {
    display: none;
  }
  .search-button {
    border-radius: 0.25rem !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
  .search-wrap-s {
    padding: 0px !important;
    width: inherit !important;
  }
  /* On large screens, increase size of muscle_label */
  .break-quoute-small {
    display:none;
  }
  .break-quoute {
    display: inherit;
  }
  .wide-footer-buttons {
    display: inherit;
  }
  .small-footer-buttons {
    display:none
  }
  .wide-footer-links {
    display: inherit;
  }
  .small-footer-links {
    display:none
  }

  .confirm-table {
    padding-left: 100px;
    padding-right: 100px;
  }

  .footer-button {
    margin-top: 30px;
    width: 180px;
    font-size: inherit;
  }

  .body_all {
    min-height: 85vh;
  }

  .MuiTypography-h6 {
    font-size: medium !important;
  }
  .MuiTypography-body1 {
    font-size: small !important;
  }
  .MuiTimelineContent-root {
    font-size: medium !important;
    padding: 6px 16px !important;
    max-width: 100% !important;
  }
  .MuiTimelineOppositeContent-root {
    font-size: medium !important;
    padding: 6px 16px !important;
    margin: 0px !important;
  }
  .order-paper {
    padding: 6px 16px !important;
    max-width: 100% !important;
  }
  #muscle-drop {
    font-size: inherit;
  }

  #home-quote {
    font-size: 1.6em;
    width:480px;
    margin-top: 20px;
    margin-left: calc(30vw - 240px) !important;
  }

  .contact-form {
    width:50%;
  }

  /* On large screens, increase size of insta-container */
  .insta-container {
    max-width: 690px;
    max-height: 790px;
  }

  /* On large screens, increase size of notfier */
  .notifier {
    margin-top: 50px;
    font-size: inherit;
  }
  .row_underline {
    font-size: 16px;
  }
  .cart-image {
    max-width: inherit;
    padding: 0px 15px !important;
  }
  .cart-body {
    padding: 20px;
  }
  .cart-total {
    font-size: 1.25rem;
  }
  .promo-input {
    width: 300px;
  }
  .checkout-button-cart {
    width: 300px;
  }
}
/************** XXXXXXL SCREENS ********************/
@media screen and (min-width: 1400px) {
  .break-quoute {
    display:none;
  }
  #home-quote {
    font-size: 1.6em;
    width:900px;
    margin-top: 20px;
    margin-left: calc(30vw - 450px) !important;
  }
}
/************** THINGS THAT NEED TO SCALE -> END ********************/
li {
  margin:0 0 4px 0;
}

.navbar-collapse.collapse.show {
}

.message {
  color: pink;
}

.exercises {
  position: relative;
  width:100%;
}

.muscle_info {
  padding-left:0;
  margin-left:0;
  z-index: 10;
}

.thumbnail_column {
  -ms-flex: 0 0 230px;
  flex: 0 0 230px;
  background-color: greenyellow;
}

@media (max-width: 690px) {
  .thumbnail_column {
    display: none;
  }
}

.flex-fixed-width-item {
  flex: 0 0 100px;
}

.no-padding {
  padding: 0 !important;
}

accordion {
  width: 100%;
}

.orderinfo-columns-1 {
  column-count: 1;
}
.orderinfo-columns-2 {
  column-count: 2;
}
.space-on-top {
  margin-top: 10px;
}