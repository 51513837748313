
#insta-container {
    height: 80px;
}

div.Caption {
    display: none;
    visibility: hidden;
}

#gx {
    background-size: contain;
    padding: 10% 0;
    text-align: center;
    width: 100%;
    height: auto;
}

.gx-image {
    animation: tronFilter 12s infinite;
    fill: #000000;
    stroke: #666;
    stroke-width: 5;
    stroke-linecap: round;
    stroke-linejoin: bevel;
}

.gx-image2 {
    animation: myShine 2s infinite;
    fill: #000000;
    stroke: #666;
    stroke-width: 5;
    stroke-linecap: round;
    stroke-linejoin: bevel;
}

@keyframes tronFilter {
    0% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #31eefd );
        filter: drop-shadow( -.75px 0px 6px #31eefd );
        stroke: #31eefd;
    }

    22% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #1d85bb );
        filter: drop-shadow( -.75px 0px 6px #1d85bb );
        stroke: #1d85bb;
    }
    /* end blue */
    42% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #884173 );
        filter: drop-shadow( -.75px 0px 6px #884173 );
        stroke: #884173;
    }

    62% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #ff0071 );
        filter: drop-shadow( -.75px 0px 6px #ff0071 );
        stroke: #ff0071;
    }
    /* end magenta */
    82% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #009997 );
        filter: drop-shadow( -.75px 0px 6px #009997 );
        stroke: #009997;
    }

    92% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #92ffd7 );
        filter: drop-shadow( -.75px 0px 6px #92ffd7 );
        stroke: #92ffd7;
    }
    /* end green */
    100% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #31eefd );
        filter: drop-shadow( -.75px 0px 6px #31eefd );
        stroke: #31eefd;
    }
}

@keyframes myShine {
    0% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #FFFFFF );
        filter: drop-shadow( -.75px 0px 6px #FFFFFF );
        stroke: #FFFFFF;
    }
    50% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #000000 );
        filter: drop-shadow( -.75px 0px 6px #000000 );
        stroke: #000000;
    }
    100% {
        -webkit-filter: drop-shadow( -.75px 0px 6px #FFFFFF );
        filter: drop-shadow( -.75px 0px 6px #FFFFFF );
        stroke: #FFFFFF;
    }
}

/***** youtube title - start ************/
.ani-p {
    text-transform: uppercase;
    letter-spacing: .5em;
    display: inline-block;
    margin: 0px;
}

.ani-span {
    font: 700 1em/1 "Oswald", sans-serif;
    letter-spacing: 0;
    display: block;
    margin: 0 auto;
    text-shadow: 0 0 80px rgba(255, 255, 225, .5);

    /* Clip Background Image */

    background: url('../assets/images/animated-text-fill.png') repeat-y;
    -webkit-background-clip: text;
    /*background-clip: text;*/

    /* Animate Background Image */

    -webkit-text-fill-color: transparent;
    -webkit-animation: ani 80s linear infinite;

    /* Activate hardware acceleration for smoother animations */

    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
}

/* Animate Background Image */

@keyframes ani {
    0% { background-position: 0% 50%; }
    100% { background-position: 100% 50%; }
}

.ani-div { font: 400 1em/1.5 "Neuton"; background: black;
    color: rgba(255,255,255,0);
    text-align: center;
    margin: 0;
    width: 100%;
    height: 100%;
}
.ani-icon {
    color:white;
    margin-top: -6px;
}

.ani-accord {
    padding: 0px !important;
    background-color: black !important;
    height: 50px;
}
.MuiAccordion-rounded:last-child,
.MuiAccordion-rounded:first-child {
    border: 0px !important;
}

.ani-card {
    border: 0px !important;
    border-radius: 0px !important;
    margin:0px !important;
    color: white;
    background-color: black;
}
.ani-columns {
    column-count: 2;
    column-gap: 0px;
    background-color: black;
}
.on-body-top {
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;
}
/*NEEDS TO SCALE - SMALL****************************/
.muscle_label {
    position: absolute;
    color: #fff;
    padding: 5px;
    background: rgba(0,0,0,0.6);
    transform: translate3d(-50%, -50%, 0);
    border-radius: 3px;
    pointer-events: none;
    z-index: 4;
    margin-left: calc(342px - 50vw);
    font-size: small;
}
.flip-body {
    margin-bottom: 10px !important;
    display:inherit;
    background-color: transparent !important;
    color: black !important;
}
.flip-body.rot {
    animation: rotate 1s infinite;
    background-color: transparent !important;
}
.flip-body:active {
    background-color: transparent !important;
}
@keyframes rotate {
    0%  { transform: rotate(0deg);  }
    100%{ transform: rotate(180deg); }
}

.on-body {
    position: absolute;
    top: 747px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;
}
.youtube-timeline {
    flex: 1 0 30%;
    max-width: 20%;
    margin: 0px !important;
    padding: 0px !important;
    font-size: x-small;
}
.inner_body {
    display: flex;
    justify-content: center;
    position: relative;
    left: -10px;
    height: 720px;
    width: 680px;
}
.body-slide {
    direction:ltr;
    width:341px;
    background-size: cover;
    overflow: hidden;
}
.body-slide.next {
    direction:rtl;
    margin-right:50px
}

.b_loading {
    overflow: visible;
}
.b_loaded {
    overflow: hidden;
}

.space-top {
    margin-top: -320px;
}

.youtube-card {
    flex: 1 0 70%;
    max-width: 80%;
    padding: 0px !important;
}
.MuiTimeline-root{
    padding: 6px 0px !important;
}
.MuiTimelineContent-root {
    padding: 6px 3px !important;
    font-size: xx-small;
}

#overlay_image {
    position: absolute;
    left:0px;
    top: 0px;
    z-index: 3;
    pointer-events: none;
}
.body-background {
    /*background-image: url("/images/367502-PAXLKG-681.jpg");*/
    width:339px;
    background-size: cover;
    overflow: hidden;
    margin-left: calc(50vw - 172px);
}
/*NEEDS TO SCALE - LARGE****************************/
@media screen and (min-width: 690px) {
    .muscle_label {
        padding: 10px;
        border-radius: 5px;
        font-size: inherit;
        margin-left: 0px;
    }
    .flip-body {
        display:none !important;
    }
    .youtube-timeline {
        font-size: medium;
    }
    .inner_body {
        height: 650px;
        left:inherit;
    }
    .space-top {
        margin-top: -260px;
    }
    .youtube-card {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .MuiTimeline-root{
        padding: 6px 16px !important;
    }
    .MuiTimelineContent-root {
        padding: 6px 16px !important;
        font-size: medium;
    }
    #overlay_image {
        left: inherit;
    }
    .body-background {
        overflow: visible;
        margin-left: 0px;
        width: 100%;
        min-width: 100%;
    }
    .body-background.next {
        margin-left: 0px;
    }
    .on-body {
        top: 70%;
    }
    .body-slide {
        width:100%;
        overflow: visible;
    }
}
/*NEEDS TO SCALE - END***************************/
.youtube-list {
    padding-top: 18px;
    width: 100%;
}

.clickable {cursor: pointer;}
.unclickable {cursor: default;}
.youtube-vid {
    width: 100%;
}

.MuiTimelineItem-missingOppositeContent:before {
    padding: 0px !important;
    width: 0px !important;
    margin: 0px !important;
    flex:0 !important;
}
.MuiTimelineDot-outlinedPrimary {
    border-color: indianred!important;
}

.timeline-title-glow {
    animation: tronFilter 3s infinite;
}
/***** youtube title - end ************/
/***** dot dot dot loader - start ************/
.spinner-dot {
    margin: 0px auto 0;
    width: 100px;
    text-align: center;
}

.spinner-dot > div {
    width: 10px;
    height: 10px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-dot .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner-dot .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}
/***** dot dot dot loader - end ************/
/***** fade animation - start ************/
/***** fade animation - end ************/
/* loading dots start *********************/
.loading-dots:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
    0%, 20% {
        color: rgba(0,0,0,0);
        text-shadow:
                .25em 0 0 rgba(0,0,0,0),
                .5em 0 0 rgba(0,0,0,0);}
    40% {
        color: white;
        text-shadow:
                .25em 0 0 rgba(0,0,0,0),
                .5em 0 0 rgba(0,0,0,0);}
    60% {
        text-shadow:
                .25em 0 0 white,
                .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
        text-shadow:
                .25em 0 0 white,
                .5em 0 0 white;}}
/* loading dots end *********************/