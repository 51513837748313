
.newin {
    width: 300px;
}

/***************************************************************/
/***************************************************************/

/***************************************************************/
.line-1{
    position: relative;
    top: 50%;
    width: 24em;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
}

/* Animation */
.anim-typewriter{
    animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter{
    from{width: 0;}
    to{width: 24em;}
}
@keyframes blinkTextCursor{
    from{border-right-color: rgba(255,255,255,.75);}
    to{border-right-color: transparent;}
}
/***************************************************************/
/* Centered text */
.centered {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}
/***************************************************************/

/*****************/
.text-col{
    color: indianred;
}
/*****************/
.ml12 {
    position: absolute;
    top: 40%;
    left: 20%;
    transform: translate(-50%, -50%);

    font-weight: 200;
    font-size: 1.8em;
    text-transform: uppercase;
    letter-spacing: 0.5em;
}

.ml12 .letter {
    display: inline-block;
    line-height: 1em;
}
/*****************/
/* Bottom right text */
.bottom-middle {
    position: absolute;
    bottom: 8px;
    left: 50%;
}
/***************************************************************/
#ProgressBar {
    border-radius: 100px;
    animation: glow 1s infinite alternate;

    margin: 0 auto;
    width: 282px;
    height: 18px;
    border: 1px solid black;
    border-radius: 0px;
    padding: 2px;
    background-color: black;
}

#Progress {
    width: 0%;
    border-left: 0px;
    border-right: 9px solid transparent;
    border-top: 12px solid white;
    height: 100%;

    animation-name: progressBar;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}
@keyframes progressBar {
    0% {
        width: 10%;
    }

    100% {
        width: 78%;
    }
}

@keyframes glow {
    from {
        box-shadow: 0 0 3px -2px white;
    }
    to {
        box-shadow: 0 0 3px 2px white;
    }
}
/*****************/
.glowtext {
    color:black;
    -webkit-animation: glowtext 1s ease-in-out infinite alternate;
    -moz-animation: glowtext 1s ease-in-out infinite alternate;
    animation: glowtext 1s ease-in-out infinite alternate;
}

@keyframes glowtext {
    from {
        text-shadow: 0 0 10px indianred;
    }
    to {
        text-shadow: 0 0 10px indianred;
    }
}
/***************************************************************/

.home-text {
    text-align: center;
}
.space-top {
    padding-top: 45vh;
}

div.Caption {
    display: none;
    visibility: hidden;
}

#gx {
    background-size: contain;
    padding: 10% 0;
    text-align: center;
    width: 100%;
    height: auto;
}
/***************************************************************/
.glowcol {
    background: 50% 100% / 50% 50% no-repeat radial-gradient(ellipse at bottom, #fff, transparent, transparent);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 10vw;
    font-family: "Source Sans Pro", sans-serif;
    animation: reveal 3000ms ease-in-out forwards 200ms,
    glowz 2500ms linear infinite 2000ms;
}

@keyframes reveal {
    80%{
        letter-spacing: 8px;
    }
    100% {
        background-size: 300% 300%;
    }
}
@keyframes glowz {
    40% {
        text-shadow: 0 0 8px #fff;
    }
}

