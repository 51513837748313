.navbar li .glyphicon {
    margin-right: 10px;
}

/* Highlighting rules for nav menu items */
.navbar .navbar-nav .active a,
.navbar .navbar-nav .active a:hover,
.navbar .navbar-nav .active a:focus {
    background-image: none;
}

.navbar {
    padding: 1rem 1rem;
}

.fade-from-bottom {
    /*-webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));*/
    background: linear-gradient(to bottom, transparent, black);
}

.navbar-light .navbar-brand {
    color: white;
}

.bg-dark {
    background-color: black !important;
    /*background: linear-gradient(to bottom, black, transparent) !important;*/
}

.navbar-dark .navbar-nav .nav-link {
    /*color: white;*/
}

.outer_container {
    width:100%;
}

.container {
    width:100%;
    min-width: 100%; 
    background-size: cover;
}

.navbasket-right{
    display: none;
}
.navbasket-collapse{
    display: inherit;
}

@media (min-width: 768px) {
    /* On large screens, convert the nav menu to a vertical sidebar */
    .navbasket-right{
        display: inherit;
    }

    .navbasket-collapse{
        display: none;
    }
    /*.navbar {
        height: 100%;
        width: calc(25% - 20px);
    }

    .navbar {
        border-radius: 0;
        border-width: 0;
        height: 100%;
    }



    .navbar-header {
        float: none;
    }

    .navbar .navbar-collapse {
        border-top: 1px solid #444;
        padding: 0;
    }

    .navbar .container-fluid {
        padding: 0;
        margin: 0;
    }

        .navbar .container-fluid .navbar-brand {
            margin: 0;
        }

    .navbar ul {
        float: none;
    }

    .navbar li {
        float: none;
        font-size: 15px;
        margin: 6px;
    }

        .navbar li a {
            padding: 10px 16px;
            border-radius: 4px;
        }

    .navbar a {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }*/
}
